import styled from "styled-components";
import { Flex } from "../Grid";

const HPContentWrapper = styled(Flex)``;

HPContentWrapper.defaultProps = {
  minWidth: ["auto", null, null, null, "690px"],
  bg: "panelPrimary",
  pr: ["0", null, "110px", null, "180px"],
  pt: [4, null, 72],
  pb: [45, null, 52],
  borderTopRightRadius: 1,
  borderBottomRightRadius: 1,
  width: ["100%", null, "60%", null, "0"],
};

export { HPContentWrapper };

import styled from "styled-components";

import { Flex } from "../Grid";
import { themeGet } from "@styled-system/theme-get";
import { rgba } from "polished";

const GradientContent = styled(Flex)`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 500;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${props => rgba(themeGet(`colors.${props.heroBg}`)(props), 1)} 41%,
    ${props => rgba(themeGet(`colors.${props.heroBg}`)(props), 0)} 80%
  );
  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
    background-color: ${props => themeGet(`colors.${props.heroBg}`)(props)};
  }
`;

GradientContent.defaultProps = {
  minHeight: ["224px", null, null, "384px", null],
  pt: [2, null, 20],
};

export { GradientContent };

import styled from "styled-components";
import { variant } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

import { Flex } from "../Grid";

const StoryBlockRight = styled(Flex)`
  position: relative;
  flex-direction: column;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -120px;
    bottom: 0;
    background-color: ${themeGet("colors.storyBlock.background")};
    width: 120px;
    border-radius: 0 0 0 6px;
  }
  @media screen and (min-width: 991.98px) {
    width: ${props => props.xlWidth};
  }

  & > div a {
    &,
    &:link,
    &:visited {
      text-decoration: none;
      color: ${themeGet("colors.linkSecondary")};
    }

    &:hover,
    &:active,
    &:focus {
      color: ${themeGet("colors.link")};
      text-decoration: underline;
    }
  }
  ${props =>
    variant({
      variants: {
        grey: {
          backgroundColor: "white.100",
          "@media screen and (max-width: 991.98px)": {
            backgroundColor: "storyBlock.background",
          },
          "&:before": {
            backgroundColor: "white.100",
            "@media screen and (max-width: 991.98px)": {
              backgroundColor: "storyBlock.background",
            },
          },
        },
        blue: {
          backgroundColor: "carInsurance.panel",
          "&:before": {
            backgroundColor: "carInsurance.panel",
          },
        },
      },
    })}
`;

StoryBlockRight.defaultProps = {
  flexGrow: 1,
  borderBottomRightRadius: 1,
  borderTopRightRadius: 1,
  backgroundColor: "storyBlock.background",
  pl: [0, null, null, 48],
  pr: [0, null, null, 40],
  py: [0, null, null, 40],
};

export { StoryBlockRight };

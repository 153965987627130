import React, { Fragment, isValidElement } from "react";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Button } from "../../style/Button";
import Link from "../Link";
import { Wysiwyg } from "../../style/Wysiwyg";
import Breadcrumb from "../Breadcrumb";
import { Image } from "../../style/Image";
import TagLink from "../TagLink";
import { URLS } from "../../const/const";
import { HPImage, HPImageWrapper } from "../../style/Hero";

const Hero = props => {
  const { variant, breadcrumbs, banner, image, categories = false } = props;
  return (
    <Wrapper
      bg={variant === "grey" ? "panelSecondary" : "panelPrimary"}
      mb={[4, null, banner?.date ? 32 : 5]}
    >
      <Container>
        <Flex
          width={"100%"}
          minHeight={["none", null, null, "380px"]}
          flexDirection={["column", null, "row"]}
          justifyContent={"space-between"}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            pt={[32, null, 20]}
            pb={[4, null, 4]}
            pr={[0, null, 32]}
            maxWidth={["none", null, 590]}
          >
            {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
            {banner?.title && (
              <Heading
                as={"h1"}
                variant={"h1"}
                dangerouslySetInnerHTML={{ __html: banner?.title }}
                mb={3}
                uppercase
              />
            )}
            {banner?.content && (
              <Fragment>
                {isValidElement(banner.content) ? (
                  <Fragment>{banner.content}</Fragment>
                ) : (
                  <Wysiwyg
                    dangerouslySetInnerHTML={{ __html: banner.content }}
                    lineHeight={3}
                    mb={26}
                  />
                )}
              </Fragment>
            )}
            {banner?.date && (
              <Wysiwyg
                dangerouslySetInnerHTML={{ __html: banner?.date }}
                color={"headlinesSecondary"}
                mb={4}
                pr={["0", null, "40%", "50%", "60%"]}
              />
            )}
            {banner?.cta && (
              <Fragment>
                {isValidElement(banner.cta) ? (
                  <Fragment>{banner.cta}</Fragment>
                ) : (
                  <Button
                    as={Link}
                    href={banner.cta.url}
                    target={banner.cta.target}
                    display="inline-block"
                    mt={"auto"}
                  >
                    {banner.cta.title}
                  </Button>
                )}
              </Fragment>
            )}

            {categories && (
              <Flex
                paddingRight={["0", null, "40%"]}
                alignItems="center"
                flexWrap="wrap"
                marginTop="auto"
              >
                {categories.map(category => (
                  <TagLink
                    key={category.id}
                    href={`${URLS.blog}${category.slug}`}
                    content={category.name}
                    variant={"white"}
                    icon={"dolar-icon"}
                  />
                ))}
              </Flex>
            )}
          </Flex>

          <HPImageWrapper mb={[0, null, -32]} ml={[-15, null, 0]}>
            <HPImage
              width={["100%", null, null, null, "590px"]}
              height={["260px", "300px", "auto", null, "394px"]}
              image={image?.sourceUrl}
            >
              <Image
                src={image?.sourceUrl}
                width={["100%", null, null, null, "590px"]}
                height={["260px", "300px", "auto", null, "394px"]}
                opacity="0"
              />
            </HPImage>
          </HPImageWrapper>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Hero;

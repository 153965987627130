import React, { Fragment } from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import {
  HPImage,
  HPImageWrapper,
  HPContentWrapper,
  HPBackgroundExtender,
} from "../../style/Hero";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import Link from "../Link";
import { Wysiwyg } from "../../style/Wysiwyg";

const HeroHp = props => {
  const {
    content: { title, titletop, cta, content },
    categories,
    image
  } = props;
  return (
    <Wrapper
      position="relative"
      mb={["mobileSpacer", null, null, "80px"]}
      bg={["panelPrimary", null, "transparent"]}
    >
      <Fragment>
        <HPBackgroundExtender />
        <Container>
          <Flex
            width={"100%"}
            flexDirection={["column-reverse", null, "row"]}
            mb={props.mb}
          >
            <HPContentWrapper>
              <Box>
                {titletop && (
                  <Text fontSize={2} lineHeight={1} color="headlines">
                    {titletop}
                  </Text>
                )}
                {title && (
                  <Heading
                    as={"h1"}
                    variant={"h1"}
                    uppercase={!categories && true}
                    dangerouslySetInnerHTML={{ __html: title }}
                    mt={0}
                    mb={3}
                  />
                )}
                {content && (
                  <Wysiwyg
                    dangerouslySetInnerHTML={{ __html: content }}
                    mb={4}
                  />
                )}
                {cta && (
                  <Button
                    as={Link}
                    href={cta.url}
                    target={cta.target}
                    display="inline-block"
                  >
                    {cta.title}
                  </Button>
                )}
              </Box>
            </HPContentWrapper>

            <HPImageWrapper>
              <HPImage image={image?.node?.sourceUrl} />
            </HPImageWrapper>
          </Flex>
        </Container>
      </Fragment>
    </Wrapper>
  );
};

export default HeroHp;

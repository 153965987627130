import styled from "styled-components";
import { Box } from "../Grid";

const MagazineBlockImage = styled(Box)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

MagazineBlockImage.defaultProps = {};

export { MagazineBlockImage };

import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { Container, Flex } from "../../style/Grid";
import { StoryWrapper } from "../../style/StoryBlock";
import StoryBlockWrapper from "./StoryBlockWrapper";

const StoryBlock = ({
  title,
  story,
  showProducts,
  showLeftPane,
  categoryTitle,
  categorySlug,
  posts,
  variant,
  reverse,
  maxWidth,
  hideLink,
  ...props
}) => (
  <Fragment>
    <StoryWrapper mt={props.mt} pt={props.pt} variant={variant ?? "light"}>
      <Container
        mb={props.mb}
        justifyContent="center"
        width={maxWidth && ["100%", null, null, null, maxWidth]}
      >
        <Flex
          mt={["-40px", null, null, 0]}
          flexDirection="column"
          maxWidth={["600px", null, null, "none"]}
          width="100%"
          alignItems={"center"}
        >
          <StoryBlockWrapper
            story={story}
            variant={variant}
            showProducts={showProducts}
            showLeftPane={showLeftPane}
            hideLink={hideLink}
            reverse={reverse}
            posts={posts}
          />

          {props.cta && props.cta}
        </Flex>
      </Container>
    </StoryWrapper>
  </Fragment>
);
export default StoryBlock;

export const StoryFragment = graphql`
  fragment StoryInformation on wp_Story {
    __typename
    ... on wp_Story {
      id
      title
      featuredImage {
        node {
          sourceUrl(size: MEDIUM_LARGE)
          id
        }
      }
      storyContent {
        storyProduct {
          fieldGroupName
          title
          products {
            ... on wp_Product {
              id
              title
              uri
              slug
              pageContent {
                template
              }
            }
          }
        }
        story {
          fieldGroupName
          author
          content
          cta {
            target
            title
            url
          }
        }
      }
    }
  }
`;

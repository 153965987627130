import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Link from "../../components/Link";
import Header from "../../components/Header";
import StoryBlock from "../../components/StoryBlock";
import { Hero } from "../../components/HeroV2";
import MagazineBlock from "../../components/MagazineBlock";

import { Button } from "../../style/Button";
import { Container, Wrapper } from "../../style/Grid";

import { URLS } from "../../const/const";

const StoriesTemplate = ({
  uri,
  data: {
    wp: {
      page: {
        title,
        featuredImage,
        pageContent: { banner },
        seo: { metaDesc },
      },
     
      posts: { nodes: posts },
      storyType: {
        stories: { nodes: stories },
      },
    },
  },
}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [storyLimit, setStoryLimit] = useState(3);
  const breadcrumbs = [
    {
      label: "S čím pomůžeme",
      link: URLS.situations,
    },
    {
      label: title,
      link: uri,
    },
  ];

  return (
    <Fragment>
      <Layout>
        <SEO title={title} description={metaDesc} />
        <Header variant="light" activeNav={URLS.situations} />

        <Hero
          breadcrumbs={breadcrumbs}
          image={featuredImage}
          banner={{
            ...banner,
            cta: banner?.cta && (
              <Button as={Link} href={banner.cta.url} target={banner.cta.target} display="inline-block" mt={15}>
                {banner.cta.title}
              </Button>
            ),
          }}
        />

        <Wrapper mt={[4, null, null, 0]} mb={[3, null, null, 5]}>
          {stories.slice(0, storyLimit).map((story, index) => (
            <StoryBlock
              key={index}
              mt={index === 0 ? 3 : [0, null, null, 2]}
              mb={index !== stories.slice(0, storyLimit).length - 1 ? [4, null, null, 0] : 4}
              pt={index === 0 ? 0 : [40, null, null, 4]}
              variant={index === 0 ? "lightGradient" : "light"}
              story={story}
              showLeftPane
              showProducts
              reverse={index % 2}
            />
          ))}

          {!(storyLimit >= stories.length) && !loadingMore && (
            <Button
              mt={[28, null, null, 4]}
              variant="secondary"
              onClick={() => {
                setLoadingMore(true);
                setStoryLimit(storyLimit + 3);
                setTimeout(() => setLoadingMore(false), 100);
              }}
            >
              Více
            </Button>
          )}
        </Wrapper>

        <Wrapper bg="gradient.gray">
          <Container mt={5}>
            <MagazineBlock posts={posts} />
          </Container>
        </Wrapper>
      </Layout>
    </Fragment>
  );
};

export default StoriesTemplate;

// Slug of the story type is a taxonomy "Typ příběhu" of the story ("Obecný" - "obecny", same for both environments)

export const pageQuery = graphql`
  query STORIES {
    wp {
      page(id: "pribehy", idType: URI) {
        title
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        pageContent {
          banner {
            fieldGroupName
            title
            titletop
            cta {
              target
              title
              url
            }
            content
          }
          template
        }
        seo {
          metaDesc
        }
      }
      storyType(id: "obecny", idType: SLUG) {
        name
        stories {
          nodes {
            title
            ...StoryInformation
          }
        }
      }
      posts(first: 4) {
        nodes {
          ...PostInformation
        }
      }
    }
  }
`;

import styled from "styled-components";
import { Box } from "../Grid";

const Wysiwyg = styled(Box)`
  p:not(:last-child) {
    margin: 0 0 0.7em;
  }
`;

Wysiwyg.displayName = "Wysiwyg";

Wysiwyg.defaultProps = {
  color: "bodyText",
  lineHeight: 2,
};

export { Wysiwyg };

import styled from "styled-components";

import { Box } from "../Grid";
import { themeGet } from "@styled-system/theme-get";

const GradientBackgroundImage = styled(Box)`
  position: absolute;
  z-index: 495;
  bottom: 0;
  top: 0;
  left: ${props => (props.leftPosition ? props.leftPosition : "40%")};
  width: 1060px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
    display: none;
  }
`;

GradientBackgroundImage.defaultProps = {};

export { GradientBackgroundImage };

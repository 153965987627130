import React, { Fragment } from "react";

import TagLink from "../TagLink";
import Link from "../Link";

import { StoryBlockLeft, StoryBlockRight, StoryBlockWrapper as StoryBlockWrapperStyle } from "../../style/StoryBlock";
import Icon from "../../style/Icon";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { Box, Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Wysiwyg } from "../../style/Wysiwyg";

const StoryBlockWrapper = ({
  title,
  story: {
    title: storyTitle,
    featuredImage,
    storyContent: { storyProduct, story },
  },
  showProducts,
  showLeftPane,
  categoryTitle,
  categorySlug,
  posts,
  variant,
  reverse,
  maxWidth,
  hideLink,
  isSlider,
  ...props
}) => (
  <StoryBlockWrapperStyle
    flexDirection={["column", null, null, "row"]}
    width={"auto"}
    height={"100%"}
    ml={[0, null, null, 0, isSlider && 15]}
    mr={[0, null, null, 0, isSlider && 15]}
    maxWidth={["600px", null, null, "none"]}
    variant={reverse ? "flip" : isSlider ? "slider" : null}
  >
    <StoryBlockLeft variant={showLeftPane ? "showLeftPane" : "hideProducts"}>
      {showLeftPane && (
        <Flex flexDirection="column" justifyContent="space-between" width="50%" p={[3, 4]}>
          {storyProduct?.title && (
            <Heading
              as="h3"
              fontSize={[20, null, null, 28]}
              lineHeight={["25px", null, null, "29px"]}
              fontWeight={300}
              style={{ textTransform: "none" }}
              mb={[4, null, null, 0]}
            >
              {storyProduct?.title}
            </Heading>
          )}

          {showProducts && (
            <Flex display={["none", "flex"]} alignItems="center" flexWrap="wrap" mt="auto">
              {storyProduct?.products?.map((product, key) => (
                <Fragment key={key}>
                  {product?.pageContent?.template ? (
                    <TagLink key={product?.id} href={"/produkt/" + product?.slug} content={product?.title} variant={"light"} icon={"dolar-icon"} />
                  ) : (
                    <TagLink key={product?.id} content={product?.title} variant={"blank"} icon={"dolar-icon"} />
                  )}
                </Fragment>
              ))}
            </Flex>
          )}
        </Flex>
      )}

      <Box width={showLeftPane ? "50%" : "100%"} flexShrink={0}>
        <Image src={featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-1.jpg").default} />
      </Box>
    </StoryBlockLeft>

    <StoryBlockRight variant={variant} xlWidth={"50%"}>
      {story.author && (
        <Text fontSize={3} lineHeight={"23px"} color={"linkSecondary"} mb={1}>
          {story.author}
        </Text>
      )}
      {storyTitle && (
        <Heading as="h4" fontSize={3} lineHeight={"23px"} fontWeight="normal" color={"link"} borderBottom="story" mb={3} pb={3}>
          {storyTitle}
        </Heading>
      )}
      {story.content && (
        <Wysiwyg
          dangerouslySetInnerHTML={{
            __html: story.content,
          }}
          mb={hideLink ? [72, null, null, 56, 48] : 4}
        />
      )}
      {story?.cta && !hideLink && (
        <Link mt={"auto"} mb={[36, null, null, 24]} alignSelf={"flex-start"} variant="smallBorderTop" href={story.cta.url.replace("product", "produkt")}>
          {story?.cta?.title}
          <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
        </Link>
      )}
    </StoryBlockRight>
  </StoryBlockWrapperStyle>
);

export default StoryBlockWrapper;

import styled from "styled-components";
import { border, variant } from "styled-system";

import { Flex } from "../Grid";

const StoryBlockLeft = styled(Flex)(
  {
    overflow: "hidden",
    position: "relative",
    top: "-32px",
    zIndex: 50,
    img: {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  border,
  variant({
    variants: {
      showLeftPane: {
        minWidth: ["auto", null, null, "500px"],
        width: ["100%", null, null, "50%"],
      },
      hideProducts: {
        width: ["100%", null, null, "300px"],
        maxHeight: ["300px", null, null, "none"],
      },
    },
  })
);

StoryBlockLeft.defaultProps = {
  borderRadius: 1,
  boxShadow: "one",
  backgroundColor: "white.100",
  flexShrink: 0,
};

export { StoryBlockLeft };

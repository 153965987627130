import React from "react";

import Link from "../Link";

import { TagLink as TagLinkStyle } from "../../style/TagLink";

const TagLink = ({ href, content, count, background, variant }) => (
  <TagLinkStyle
    as={href && Link}
    href={href}
    background={background}
    variant={variant}
  >
    {content}
    {count && <span>{`(${count})`}</span>}
  </TagLinkStyle>
);

export default TagLink;

import styled from "styled-components";
import { Box } from "../Grid";

const HPImageWrapper = styled(Box)``;

HPImageWrapper.defaultProps = {
  flexGrow: [0, null, null, null, 1],
  maxWidth: ["none", null, null, null, "590px"],
  ml: ["-15px", null, "-80px"],
  mr: ["-15px", null, "0"],
  width: ["auto", null, "calc(40% + 80px)", null, "auto"],
};

export { HPImageWrapper };

import styled from "styled-components";
import { variant } from "styled-system";
import themeGet from "@styled-system/theme-get";

const TagLink = styled("span")`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;

  padding: 5px 12px;
  margin-bottom: 8px;

  border-radius: 11.5px;
  transition: background-color 200ms;

  svg {
    margin-right: 8px;
  }

  span {
    margin-left: 4px;
    font-size: 10px;
    font-weight: normal;
    color: ${themeGet("colors.linkSecondary")};
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  ${props =>
    variant({
      variants: {
        light: {
          color: "link",
          backgroundColor: "panelPrimary",
          "&:hover, &:active": {
            backgroundColor: "panelSecondary",
          },
        },
        white: {
          color: "link",
          backgroundColor: "white.100",
          "&:hover": {
            backgroundColor: "white.50",
          },
        },
        outline: {
          color: "link",
          backgroundColor: "none",
          border: "1px solid #80B1B7",
          "&:hover, &:active": {
            backgroundColor: "panelSecondary",
          },
        },
        blank: {
          backgroundColor: "panelSecondary",
          color: "bodyText",
        },
      },
    })}
`;

TagLink.defaultProps = {};

export { TagLink };

import styled from "styled-components";
import { Box } from "../Grid";

const HPBackgroundExtender = styled(Box)``;

HPBackgroundExtender.defaultProps = {
  position: "absolute",
  width: ["15px", null, "31px", null, "auto"],
  right: ["calc(50% + 600px)"],
  bg: "panelPrimary",
  top: "0",
  bottom: "0",
  left: "0",
};

export { HPBackgroundExtender };

import styled from "styled-components";
import { variant } from "styled-system";

import { Flex } from "../Grid";

const StoryBlockWrapper = styled(Flex)`
  ${props =>
    variant({
      variants: {
        flip: {
          flexDirection: ["column", null, null, "row-reverse !important"],
          "& > div": {
            "&:last-of-type": {
              paddingLeft: [0, null, null, 28],
              paddingRight: [0, null, null, 5],
              borderRadius: "6px 0 0 6px",
              ["@media screen and (max-width: 991.98px)"]: {
                content: "none",
              },
              "&:before": {
                display: ["none", null, null, "block"],
                left: "auto",
                right: "-120px",
                borderRadius: "0 0 6px 0",
                ["@media screen and (max-width: 991.98px)"]: {
                  content: "none",
                },
              },
            },
          },
        },
        slider: {
          ["@media screen and (max-width: 991.98px) and (min-width: 700px) {"]: {
            marginLeft: "calc((100vw - 62px - 600px) / 2)",
          },
        },
      },
    })}
`;

StoryBlockWrapper.defaultProps = {
  mt: 4,
  position: "relative",
};

export { StoryBlockWrapper };

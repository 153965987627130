import React from "react";
import { Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import Icon from "../../style/Icon";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { Link as FakeLink } from "../../style/Link";
import { MagazineBlockItemHeading, MagazineBlockItemDescription, MagazineBlockItemDetails, MagazineBlockItemDetailsItem } from "../../style/MagazineBlock";
import Link from "../Link";
import { MagazineBlockImage } from "../../style/MagazineBlock/MagazineBlockImage";
import moment from "moment";
import { URLS } from "../../const/const";
import { graphql } from "gatsby";

require("moment/locale/cs");

const MagazineBlock = ({ mt, mb, posts, categoryTitle, categorySlug, variant }) => (
  <Flex flexDirection="column" alignItems={"flex-start"} mb={mb ?? ["mobileSpacer", null, 4]} mt={mt}>
    <Link href={categoryTitle ? `${URLS.blog}${categorySlug}` : URLS.blog} variant={"noUnderline"}>
      <Heading as="h3" fontWeight="300" display="flex" alignItems="center" fontSize={[4, null, 5]} lineHeight={0} mb={1}>
        MAGAZÍN
        <Icon ml={14} icon="more" size={["17px", null, "23px"]} style={{ transform: "rotate(-90deg)", display: null }} color="cta" />
      </Heading>
    </Link>
    <Text mb={3} fontWeight={"semiBold"} fontSize={[18, null, null, 3]} lineHeight={["21px", null, null, "23px"]} color="headlinesSecondary">
      Nejnovější články {categoryTitle && `o ${categoryTitle.toLowerCase()}`}
    </Text>

    <Flex justifyContent="space-between" flexWrap="wrap">
      {posts &&
        posts.map(post => (
          <Flex flexDirection="column" key={post.id} width={["100%", "calc(50% - 20px)", null, "calc(25% - 22px)"]} mb={[4, null, null, 0]}>
            <FakeLink as={Link} variant={"noUnderline"} href={`${URLS.blog}${post.slug}`}>
              <MagazineBlockImage
                width={["100%"]}
                height={["160px"]}
                borderRadius={1}
                mb={3}
                image={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
              >
                <Image
                  src={post?.featuredImage?.node?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                  borderRadius={1}
                  border={2}
                  width={["100%"]}
                  height={["160px", "160px"]}
                  opacity="0"
                />
              </MagazineBlockImage>
              <MagazineBlockItemHeading as="h2" fontSize={[2, null, 3]} lineHeight={"23px"} dangerouslySetInnerHTML={{ __html: post.title }} />
              <MagazineBlockItemDetails as={"ul"}>
                <MagazineBlockItemDetailsItem as={"li"} variant={variant}>
                  {moment(post.date)
                    .locale("cs")
                    .format("D. MMMM YYYY")}
                </MagazineBlockItemDetailsItem>

                {categorySlug && (
                  <MagazineBlockItemDetailsItem as={"li"} variant={variant}>
                    {categoryTitle}
                  </MagazineBlockItemDetailsItem>
                )}

                {post?.categories?.nodes?.map(category =>
                  categorySlug ? (
                    category.slug !== categorySlug && (
                      <MagazineBlockItemDetailsItem key={category.id} as={"li"} variant={variant}>
                        {category.name}
                      </MagazineBlockItemDetailsItem>
                    )
                  ) : (
                    <MagazineBlockItemDetailsItem key={category.id} as={"li"} variant={variant}>
                      {category.name}
                    </MagazineBlockItemDetailsItem>
                  )
                )}
              </MagazineBlockItemDetails>
              <MagazineBlockItemDescription dangerouslySetInnerHTML={{ __html: post.content }} />

              <FakeLink variant="smallBorderTop" mt={3}>
                celý článek
                <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
              </FakeLink>
            </FakeLink>
          </Flex>
        ))}
    </Flex>
  </Flex>
);

export default MagazineBlock;

export const PostsFragment = graphql`
  fragment PostInformation on wp_Post {
    id
    title
    slug
    uri
    date
    content: excerpt
    featuredImage {
      node {
        sourceUrl(size: MEDIUM)
      }
    }
    categories {
      nodes {
        id
        name
        slug
      }
    }
  }
`;

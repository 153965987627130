import styled from "styled-components";

import { HPBackgroundExtender } from "./HPBackgroundExtender";

const GradientBackgroundExtender = styled(HPBackgroundExtender)`
  left: ${props => (props.side === "left" ? "0" : "auto")};
  right: ${props => (props.side === "right" ? "0" : "auto")};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

GradientBackgroundExtender.defaultProps = {
  width: ["15px", null, "31px", null, "calc(50% - 600px)"],
};

export { GradientBackgroundExtender };

//  bottom="0" width={["15px", null, "31px", null, "calc(50% - 600px)"]}
// position="absolute" top="0" right="0" bottom="0" width={["15px", null, "31px", null, "calc(50% - 600px)"]}

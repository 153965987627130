import styled from "styled-components";
import { variant } from "styled-system";

import { Wrapper } from "../Grid";

const StoryWrapper = styled(Wrapper)(
  variant({
    variants: {
      light: {
        backgroundColor: ["storyBlock.background", null, null, "transparent"],
      },
      lightGradient: {
        backgroundColor: ["storyBlock.background", null, null, "transparent"],
        ["@media screen and (max-width: 991.98px)"]: {
          background: "linear-gradient(0deg, #EBEEF0 93%, #FFFFFF 93%)",
        },
      },
      grey: {
        backgroundColor: "storyBlock.background",
      },
      greyGradient: {
        backgroundColor: ["storyBlock.background", null, null, "transparent"],
        ["@media screen and (max-width: 991.98px)"]: {
          background: "linear-gradient(0deg, #EBEEF0 93%, #FFFFFF 93%)",
        },
      },
      blue: {
        backgroundColor: ["carInsurance.panel", null, null, "transparent"],
      },
      blueGradient: {
        backgroundColor: ["carInsurance.panel", null, null, "transparent"],
        ["@media screen and (max-width: 991.98px)"]: {
          background: "linear-gradient(0deg, #E1EEF5 93%, #FFFFFF 93%)",
        },
      },
    },
  })
);

StoryWrapper.displayName = "StoryWrapper";

StoryWrapper.defaultProps = {
  position: "relative",
};

export { StoryWrapper };

import styled from "styled-components";
import { Box } from "../Grid";
import themeGet from "@styled-system/theme-get";

const HPImage = styled(Box)`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;

  @media screen and (min-width: ${themeGet("breakpoints.md")}) {
    transform: translateY(-50%);
  }

  @media screen and (min-width: 991.98px) {
    transform: none;
  }
`;

HPImage.defaultProps = {
  position: "relative",
  width: ["100%", null, "40vw", "100%", "100%"],
  height: ["40vw", null, "35vw", "100%"],
  top: ["0", null, "50%", 4],
  transform: ["none", null, "translateY(-50%)"],
  borderRadius: ["0px", null, 1],
  boxShadow: "one",
  maxHeight: "394px",
};

export { HPImage };
